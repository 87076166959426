.custom-headline {
    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        font-size: inherit;
        font-weight: inherit;
    }
}

.text-lg {
    @include font(normal, 35, 49px, black, 0, 'Arial');
    letter-spacing: -0.03em;
}

.base-text {
    @include font(900, 13, 15px, 0, uppercase, $fontMardoto);

    .icon {
        display: inline-block;
        vertical-align: -3px;
        margin-right: 3px;
    }

    &:not(:last-child):not(:only-child) {
        margin-bottom: 7px;
    }
}

.base_text {
    @include font(500, 16, 24px, 0, 0, $fontMardoto);
}