@import "../../assets/scss/utils/module";

.plate_card_user {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 10px;
    padding-right: 5px;
    padding-left: 5px;

    &__label {
        @include font(normal, 14, 29px, black, 0);
    }

    &__value {
        @include font(500, 15, 32px, black, 0);
    }

    &__suffix {
        margin-left: 4px;
    }

    &:not(:nth-last-child(-n)):after {
        content: '';
        height: 1px;
        background-image: linear-gradient(90deg, #C4C4C4, #C4C4C4 55%, transparent 55%, transparent 100%);
        background-size: 8px 1px;
        border: none;
        background-position: bottom 0 left -15px;
        background-repeat: repeat-x;
        position: absolute;
        bottom:0;
        left: 8px;
        right: 12px;
    }

    &:not(:first-child) {
        &:after {
            left: -24px;
        }
    }
}