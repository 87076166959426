@import "./assets/scss/utils/module";
@import './assets/scss/core/_core.scss';
@import './assets/scss/layouts/_grid.scss';
//@import './assets/fonts/icons/iconfont.css';
@import './assets/scss/core/_fonts.scss';
@import './assets/scss/core/_typography.scss';
@import './assets/scss/core/_antd-customization.scss';

html,
body,
#root {
    height: 100%;
    min-height: 100%;
}

.icon {
    display: inline-block;
}

.form_field {
    @extend %flex;
    @include flex-flow(row, wrap);
    position: relative;
    background: transparent;
    margin-bottom: 30px;

    label {
        @extend %form-label;
    }

    &.form-error {
        .textarea_field,
        .input_panel {
            box-shadow: 0 0 5px $color-red;
        }

        input,
        .form_suffix,
        .form_prefix,
        textarea {
            border-color: rgba($color-red, 0.75);
        }
    }

    &.disabled {
        label,
        .form_prefix,
        .form_suffix {
            opacity: 0.35;
        }

        .textarea_field,
        .input_panel {
            box-shadow: 0 0 0;
        }

        input,
        textarea {
            border-color: #DDDDDD;
        }
    }

    &-sm {
        margin-bottom: 20px;
    }
}

// Accordion styles
.Collapsible__contentOuter {
    transition: height 350ms linear 0s, overflow 0.5s ease 0.4s, opacity 0.5s ease 0.29s !important;
    opacity: 0; }

.Collapsible__trigger.is-open + .Collapsible__contentOuter {
    overflow: visible !important;
    opacity: 1; }

.Collapsible {
    border-bottom: 1px solid $color-grey--lighten;
    margin-bottom: 60px;
}

.Collapsible__trigger {
    position: relative;
    background-color: transparent;
    border: none;
    outline: none;
    transition: 0.4s;
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: inherit;
    margin: 0 0 20px;

    &:after {
        content: "\e906";
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 0;
        transition: 0.4s;
        transform: translateY(-50%);
        font-family: 'icomoon' !important;
        font-size: 17px;
        color: black;
    }

    &.is-open {
        &:after {
            transform: translateY(-50%) rotate(180deg);
        }
    }
}

.panel_inner {
    padding: 10px 0 20px;
}

.base_text {
    @include font(normal, 16, 27px, black, 0);
}

.base_link {
    @include font(bold, 18, 27px, $color-main, 0);
    text-decoration: none;
    display: table;

    .icon {
        @extend %inline-middle;
        margin-right: 10px;
    }
}

.link_btn {
    @include font(500, 11, 1, $color-main, uppercase, $fontMontserratArm);
    text-decoration: none;
    display: table;

    &[type] {
        border: 0;
        outline: 0;
        background: initial;
        padding: 0;

        &:not(:disabled) {
            cursor: pointer;
        }
    }

    .icon {
        vertical-align: -1px;
        margin-right: 6px;
    }
}

.clone_panel {
    &:not(:first-child):not(:only-child) {
        border-top: 1px solid #DDDDDD;
        padding-top: 20px;
        margin-top: 10px;
    }
}

.cust_btn {
    .icon {
        vertical-align: middle;
    }

    span {
        display: inline-block;
        vertical-align: middle;
        font-weight: bold;
        margin-left: 12px;
    }
}

.base_btn {
    &:hover {
        color: white;

        span {
            color: white;
        }
    }
}

@media (max-width: 1200px) {
    .spaced-md {
        margin-bottom: 30px;
    }
}

@media (max-width: 768px) {
    .md-align-center {
        justify-content: center;
    }

    .md-text-center {
        text-align: center;
    }
}