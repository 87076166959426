@import "../../assets/scss/utils/module";

.guarantor {
    background-color: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 22px ;

    &__icon {
        margin-right: 18px;
    }

    &_label {
        @include font(normal, 16, 26px, black, 0);
    }

    &_name {
        @include font(500, 20, 26px, black, 0);
    }
}