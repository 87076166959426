@import "../../assets/scss/utils/module";

.menu {
    @include flex(0,0, 300px);
    max-width: 300px;
    padding: 32px 28px;

    background: #303030 url(../../assets/img/shape-menu.png) top 671px left 0/contain no-repeat;
}

.brand_logo {
    text-align: center;
    display: table;
    margin: 0 auto;

    img {
        max-width: 243px;
    }
}

.new_app {
    margin-top: 45px;
    margin-bottom: 30px;
}

.new_app_disabled {
  margin-top: 45px;
  margin-bottom: 30px;
  opacity: 0.8;
  span {
    cursor: not-allowed;
  }
}

.nav {
    margin: 20px 0 0;

    &:not(:last-child) {
        &:after {
            content: '';
            display: block;
            background-color: #565656;
            width: 75px;
            height: 1px;
            margin: 19px auto 0;
        }
    }

    &__link {
        @include font(normal, 18, 40px, #565656, 0);
        @extend %primary-transition;
        text-decoration: none;
        //opacity: 0.4;
        display: table;
        margin: 0 auto;

        &:hover {
            color: white;
        }

        &.active {
            color: white;
        }
    }
}

.burger {
    @extend %primary-transition;
    position: absolute;
    top: 10px;
    left: 15px;
    z-index: 5;
    width: 18px;
    height: 2px;
    background: black;
    border-radius: 100px;

    &:before,
    &:after {
        content: "";
        @extend %primary-transition;
        position: absolute;
        width: 100%;
        height: 2px;
        background: black;
        border-radius: 100px;
    }

    &:before {
        top: 5px;
        left: 0;
    }

    &:after {
        bottom: 5px;
        left: 0;
    }

    &.active {
        transition: opacity 0.5s ease 0.29s;
        position: fixed;
        top: 40px;
        background: transparent;
        opacity: 1;

        &:before,
        &:after {
            background: white;
        }

        &:before {
            top: 0;
            transform: rotate(-45deg);
        }

        &:after {
            bottom: 0;
            transform: rotate(45deg);
        }
    }
}

@media (min-width: 1201px) {
    .burger_panel {
        display: none;
    }
}

@media (max-width: 1200px) {
    .menu {
        @include transform(translateX(-100%));
        flex: 0 0 350px;
        max-width: 350px;
        padding-top: 28px;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 2;

        &:before {
            content: '';
            background: rgba(0, 0, 0, 0.5);
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 1000%;
            visibility: hidden;
            opacity: 0;
            z-index: -1;
        }

        &.active {
            @include transform(translateX(0));
            transition: all 0.25s ease;

            &:before {
                transition: all 0.25s ease;
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .brand_logo {
        padding-left: 15px;
    }
}