@import "../../assets/scss/utils/module";

.success {
    padding-top: 84px;
}

.prize-win-amount-label-wrapper {
    text-align: center;
    padding: 20px 0;
    border: 2px solid $color-green;
    border-radius: 16px;
    margin-bottom: 45px;
}