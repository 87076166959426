@import "../../assets/scss/utils/module";

.plate_panel {
    margin-bottom: 30px;
}

.plate {
    position: relative;
    border-radius: 5px;
    height: 100%;
    padding: 20px 10px 11px 20px;

    &__label {
        @include font(normal, 18, 28px, white, 0);
    }

    &__value {
        @include font(900, 35, 52px, white, 0);
    }

    &__type {
        @include font(normal, 14, 1, white, 0);
        text-align: right;
        margin-top: -8px;
    }

    &:after {
        content: '';
        background: url(../../assets/img/shape-plate.png) bottom/100% no-repeat;
        position: absolute;
        top: 0;
        bottom:0;
        left: 0;
        right: 0;
    }

    &.green {
        background: $gradient-green;
    }

    &.grey {
        background: $gradient-grey;
    }
}