@import "../../assets/scss/utils/module";

.person {
    background-color: $color-grey--extralight;
    border-radius: 100px;
    padding: 10px 20px 10px 15px;
    display: table;

    &__name {
        @include font(normal, 16, 1, black, 0);
        @extend %inline-middle;
    }

    &__avatar {
        @extend %inline-middle;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }
}