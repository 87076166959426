@import "../utils/variables";

@font-face {
    font-family: 'icomoon';
    src: url('../../fonts/icons/icomoon.eot') format('embedded-opentype'),
    url('../../fonts/icons/icomoon.ttf') format('truetype'),
    url('../../fonts/icons/icomoon.woff') format('woff'),
    url('../../fonts/icons/icomoon.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: $fontMardoto;
    src: local('Mardoto Regular'), local('Mardoto-Regular'),
    url('../../fonts/Mardoto-Regular.woff2') format('woff2'),
    url('../../fonts/Mardoto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $fontMardoto;
    src: local('Mardoto Italic'), local('Mardoto-Italic'),
    url('../../fonts/Mardoto-Italic.woff2') format('woff2'),
    url('../../fonts/Mardoto-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $fontMardoto;
    src: local('Mardoto Medium'), local('Mardoto-Medium'),
    url('../../fonts/Mardoto-Medium.woff2') format('woff2'),
    url('../../fonts/Mardoto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $fontMardoto;
    src: local('Mardoto BoldItalic'), local('Mardoto-BoldItalic'),
    url('../../fonts/Mardoto-BoldItalic.woff2') format('woff2'),
    url('../../fonts/Mardoto-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $fontMardoto;
    src: local('Mardoto ThinItalic'), local('Mardoto-ThinItalic'),
    url('../../fonts/Mardoto-ThinItalic.woff2') format('woff2'),
    url('../../fonts/Mardoto-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $fontMardoto;
    src: local('Mardoto MediumItalic'), local('Mardoto-MediumItalic'),
    url('../../fonts/Mardoto-MediumItalic.woff2') format('woff2'),
    url('../../fonts/Mardoto-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $fontMardoto;
    src: local('Mardoto Light'), local('Mardoto-Light'),
    url('../../fonts/Mardoto-Light.woff2') format('woff2'),
    url('../../fonts/Mardoto-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $fontMardoto;
    src: local('Mardoto Thin'), local('Mardoto-Thin'),
    url('../../fonts/Mardoto-Thin.woff2') format('woff2'),
    url('../../fonts/Mardoto-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $fontMardoto;
    src: local('Mardoto LightItalic'), local('Mardoto-LightItalic'),
    url('../../fonts/Mardoto-LightItalic.woff2') format('woff2'),
    url('../../fonts/Mardoto-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $fontMardoto;
    src: local('Mardoto BlackItalic'), local('Mardoto-BlackItalic'),
    url('../../fonts/Mardoto-BlackItalic.woff2') format('woff2'),
    url('../../fonts/Mardoto-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $fontMardoto;
    src: local('Mardoto Black'), local('Mardoto-Black'),
    url('../../fonts/Mardoto-Black.woff2') format('woff2'),
    url('../../fonts/Mardoto-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $fontMardoto;
    src: local('Mardoto Bold'), local('Mardoto-Bold'),
    url('../../fonts/Mardoto-Bold.woff2') format('woff2'),
    url('../../fonts/Mardoto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $fontMontserratArm;
    src: local('Montserratarm Black'), local('Montserratarm-Black'),
    url('../../fonts/Montserratarm-Black.woff2') format('woff2'),
    url('../../fonts/Montserratarm-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $fontMontserratArm;
    src: local('Montserratarm SemiBold'), local('Montserratarm-SemiBold'),
    url('../../fonts/Montserratarm-SemiBold.woff2') format('woff2'),
    url('../../fonts/Montserratarm-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $fontMontserratArm;
    src: local('Montserratarm Bold'), local('Montserratarm-Bold'),
    url('../../fonts/Montserratarm-Bold.woff2') format('woff2'),
    url('../../fonts/Montserratarm-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $fontMontserratArm;
    src: local('Montserratarm Light'), local('Montserratarm-Light'),
    url('../../fonts/Montserratarm-Light.woff2') format('woff2'),
    url('../../fonts/Montserratarm-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $fontMontserratArm;
    src: local('Montserratarm Thin'), local('Montserratarm-Thin'),
    url('../../fonts/Montserratarm-Thin.woff2') format('woff2'),
    url('../../fonts/Montserratarm-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $fontMontserratArm;
    src: local('Montserratarm ExtraBold'), local('Montserratarm-ExtraBold'),
    url('../../fonts/Montserratarm-ExtraBold.woff2') format('woff2'),
    url('../../fonts/Montserratarm-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $fontMontserratArm;
    src: local('Montserratarm ExtraLight'), local('Montserratarm-ExtraLight'),
    url('../../fonts/Montserratarm-ExtraLight.woff2') format('woff2'),
    url('../../fonts/Montserratarm-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $fontMontserratArm;
    src: local('Montserratarm Medium'), local('Montserratarm-Medium'),
    url('../../fonts/Montserratarm-Medium.woff2') format('woff2'),
    url('../../fonts/Montserratarm-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $fontMontserratArm;
    src: local('Montserratarm Regular'), local('Montserratarm-Regular'),
    url('../../fonts/Montserratarm-Regular.woff2') format('woff2'),
    url('../../fonts/Montserratarm-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}