@import "../../assets/scss/utils/module";

.user_modal {
    background-color: white;
    border-radius: 25px;
    padding: 0;
    min-width: 650px;
    
    .ant-modal-footer {
        display: none;
    }
}

.user_modal_header {
    background: linear-gradient(90deg, $color-green 0%, #00A740 100%);
    //border-radius: 25px 25px 0 0;
    padding: 26px 30px;
}

.ant-modal-content {
    border-radius: 25px;
    overflow: hidden;
    box-shadow: 0 0 0;
}

.ant-modal-body {
    padding: 0;
}

.ant-modal-close {
    top: 11px;
    right: 10px;
}

.ant-modal-close-x {
    font-family: 'icomoon' !important;
    display: inline-block;
    font-size: 17px;
    color: white;

    svg {
        display: none;
    }

    &:before {
        content: "\e921";
    }
}

.user_modal_main {
    padding: 60px 20px 20px;
}