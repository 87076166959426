%flex {
    display: -ms-flexbox;
    display: flex;
}

%align-middle {
    -ms-flex-align: center;
    align-items: center;
}

%align-top {
    -ms-flex-align: flex-start;
    align-items: flex-start;
}

%align-bottom {
    -ms-flex-align: flex-end;
    align-items: flex-end;
}

%align-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
}

%align-between {
    -ms-flex-pack: space-between;
    justify-content: space-between;
}

%align-around {
    -ms-flex-pack: space-around;
    justify-content: space-around;
}

%align-center {
    -ms-flex-pack: center;
    justify-content: center;
}

%align-left {
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
}

%align-right {
    -ms-flex-pack: flex-end;
    justify-content: flex-end;
}

%justify-stretch {
    -ms-flex-pack: stretch;
    justify-content: stretch;
}

%primary-transition {
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

%user-cursor {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

%not-selectible {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}

%inline-middle {
    display:inline-block;
    vertical-align: middle;
}

%hidden {
    visibility: hidden;
    opacity:0;
}

%show {
    visibility: visible;
    opacity: 1;
}

%wrapped {
    @include transition(max-height, 0.5s, ease);
    max-height: 0;
    overflow: hidden;
}

%slide {
    @include transition(max-height, 1s, ease);
    max-height: 500px;
}

%text-clamb-4 {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 100px;
}

%clip-overflow {
    overflow: hidden;
    -webkit-overflow-scrolling: hidden;
}

%text-background {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

%form-label {
    @include font(500, 14, 16px, black, 0);
    @include transition(all, .2s, ease);
    @include order(1);
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 10px;

    &.bold {
        font-weight: bold;
    }
}

%form-elements-font {
    @include font(normal, 17, 1, black, 0);
}

%form-elements-sizes {
    display: block;
    width: 100%;
    height: 50px;
    padding: 0 15px 0;
}

%form-elements-style {
    @include transition(all, 0.3s, ease-out);
    //@include order(2);
    outline: 0;
    background-color: white;
    border: 1px solid $color-grey--lighten;
    border-radius: 5px;

    &:hover,
    &:focus {
        border-color: $color-grey--lighten;
        box-shadow: 0 0 0;
        outline: 0;

        //& ~ label {
        //    color: $color-green;
        //}
    }

    &:disabled {
        //@extend %not-selectible;
        opacity: 0.5;

        & ~ label {
            @extend %not-selectible;
            opacity: 0.5;
        }
    }
}