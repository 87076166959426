@import "../../assets/scss/utils/module";

.pie-chart-wrapper {
    position: relative;
    max-width: 246px;
    //margin: 0 auto;

    //&:before {
    //    content: '';
    //    border: 14px solid lighten(#C4C4C4, 20%);
    //    position: absolute;
    //    top: 0;
    //    left: 0;
    //    right: 0;
    //    bottom: 0;
    //    border-radius: 50%;
    //}

    &:after {
        content: '';
        //border: 9px solid rgba(#C4C4C4, 0.5);
        background-color: white;
        @include center-absolute(both);
        width: 78%;
        height: 78%;
        border-radius: 50%;
    }

    text {
        @include font(900, 15, 22px, 0, uppercase, $fontMardoto);
        fill: black;
    }
}

.ant-table-tbody {
  .hidden .ant-table-row-expand-icon-cell div {
    display: none;
  }
}