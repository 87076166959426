@import "../../assets/scss/utils/module";

.list_card {
    @extend %primary-transition;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    display: block;
    overflow: hidden;
    height: calc(100% - 43px);
    margin-bottom: 43px;

    &__img {
        position: relative;

        &:before {
            content: '';
            background: linear-gradient(360deg, #000000 -5.27%, rgba(0, 0, 0, 0) 48.91%);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &_info {
        @include font(normal, 15, 18px, rgba(white, 0.5), 0, $fontMardoto);
        position: absolute;
        bottom: 20px;
        left: 20px;
        z-index: 2;
    }

    &_badge {
        position: absolute;
        top: -11px;
        right: 28px;
    }

    &__title {
        @include font(bold, 20, 30px, $color-green, 0, $fontMardoto);
        position: relative;
        padding: 25px 20px;
    }

    &:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }
}