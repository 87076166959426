@import "../../assets/scss/utils/module";

.action_info {
    position: relative;
    background-color: #ECF1EE;
    color: #ECF1EE;
    border-radius: 10px;
    padding: 20px 28px;
    margin-bottom: 46px;

    &__icon {
        margin-right: 15px;
    }

    &__desc {
        @include font(normal, 16, 1.8, black, 0);
    }
    
    &:after {
        content: '';
        @include transform(translateX(-50%) rotate(45deg));
        position: absolute;
        bottom: -10px;
        left: 50%;
        width: 32px;
        height: 32px;
        background-color: currentColor;
        z-index: -1;
    }
}