@import "../../assets/scss/utils/module";

.notification_btn {
    background-color: $color-green;
    border-radius: 50%;
    width: 43px;
    height: 43px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    &__count {
        @include font(bold, 11, 19px, white, 0);
        background-color: $color-red;
        border-radius: 50%;
        display: inline-block;
        width: 19px;
        height: 19px;
        text-align: center;
        position: absolute;
        top: -5px;
        right: -4px;
    }

    .icon {
        color: white;
    }
}