@import '../utils/_module.scss';

.mb_65 {
    margin-bottom: 65px;
}

.mb_60 {
    margin-bottom: 60px;
}

.mb_55 {
    margin-bottom: 55px;
}

.mb_50 {
    margin-bottom: 50px;
}

.mb_45 {
    margin-bottom: 45px;
}

.mb_40 {
    margin-bottom: 40px;
}

.mb_35 {
    margin-bottom: 35px;
}

.mb_30 {
    margin-bottom: 30px;
}

.mt_30 {
    margin-top: 30px;
}

.mt_35 {
    margin-top: 35px;
}

.mb_25 {
    margin-bottom: 25px;
}

.mb_20 {
    margin-bottom: 20px;
}

.mb_15 {
    margin-bottom: 15px;
}

.mb_10 {
    margin-bottom: 10px;
}

.mt_10 {
    margin-top: 10px;
}

.m_0 {
    margin: 0;
}

.pt_5 {
    padding-top: 5px;
}

.pt_36 {
    padding-top: 36px;
}

.pt_10 {
    padding-top: 10px;
}

.pt_24 {
    padding-top: 24px;
}

.p_0 {
    padding: 0;
}

.fluid-x {
    width: 100%;
}

.fluid-y {
    height: 100%;
}

.fluid-y-auto {
    height: inherit;
}

.row-wrap {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.row-expanded {
    margin-left: -15px;
    margin-right: -15px;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.relative {
    position: relative;
}

.text-center{
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-uppercase {
    text-transform: uppercase;
}

.base-case {
    text-transform: inherit;
}

.no-decor {
    text-decoration: none;
}

.underlined {
    text-decoration: underline;
}

.text-bold {
    font-weight: bold;
}

.text-black {
    font-weight: 900;
}

.disabled {
    pointer-events: none;
    user-select: none;
}

.cust_btn {
    border: 0;
    outline: 0;
    padding: 0;
    cursor: pointer;
    background-color: inherit;
}

.color-red {
    color: $color-red;
}

.color-green {
    color: $color-main;
}

.color-white {
    color: white;
}

.inline_elem {
    display: inline-block;
    vertical-align: middle;
}

.block_elem {
    display: block;
}

.clamp-3 {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 140px;
}
