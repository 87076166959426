@import '~antd/dist/antd.min.css';

.ant-form-item {
    position: relative;
    background: transparent;
    margin-bottom: 30px;

    &-required {
        &:before {
            display: none;
        }
    }
}

.ant-form-item-children {
    display: block;
}

.ant-form-item-label {
    @extend %form-label;
    text-align: left;
    
    > label:after {
        display: none;
    }
}

.ant-input-affix-wrapper {
    @extend %flex;
    //box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 100%;

    /* Ant input prefix */
    .ant-input-prefix {
        @include font(normal, 14, 48px, black, 0);
        @include transition(all, 0.3s, ease-out);
        background-color: white;
        border: 1px solid $color-grey--lighten;
        border-right: 0;
        border-radius: 5px 0 0 5px;
        text-align: center;
        height: 50px;
        padding-left: 15px;
        position: static;
        left: 0;
        transform: translateY(0);

        & + input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 0;
        }
    }

    /* Ant input suffix */
    .ant-input-suffix {
        @include font(normal, 14, 48px, black, 0);
        @include transition(all, 0.3s, ease-out);
        background-color: white;
        border: 1px solid $color-grey--lighten;
        border-left: 0;
        border-radius: 0 5px 5px 0;
        text-align: center;
        height: 50px;
        padding-right: 15px;
        position: static;
        right: 0;
        transform: translateY(0);
    }
}

.input-sm {
    .ant-input {
        padding: 0 9px;
    }

    &.ant-input-affix-wrapper .ant-input:not(:last-child) {
        padding-right: 9px;
    }
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:active,
.ant-select-selection:focus,
.ant-select-selection:hover,
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled), .ant-input-number:hover {
    border-color: $color-grey--lighten;
}

.ant-select-open .ant-select-selection,
.ant-select-focused .ant-select-selection,
.ant-select-selection:active,
.ant-select-selection:focus {
    box-shadow: 0 0 0;
}

/* Ant input */
.ant-input,
.ant-calendar-input {
    @extend %form-elements-style;
    @extend %form-elements-sizes;
    @include font(normal, 17, 1, black, 0);

    &:-webkit-autofill {
        box-shadow: inset 0 0 0 50px #fff !important; /* Цвет фона */
    }

    &::-webkit-inner-spin-button {
        display: none;
    }

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder {
        @extend %form-elements-font;
        opacity: 0.5;
    }

    &[type="file"] {
        background-repeat: no-repeat;
        background-size: auto;
        background-position: top 50% right 25px;
        cursor: pointer;
        padding-right: 55px;

        &.filled {
            background-size: 0;

            &::-webkit-file-upload-button {
                display: block;
            }
        }
    }

    &::-webkit-file-upload-button {
        display: none;
        width: 0;
        height: 0;
        padding: 0;
        border: 0;
        outline: 0;
        margin: 20px auto 0;
    }

    &:not(:last-child):not(.ant-input-suffix):not(.ant-input-prefix):not(.ant-calendar-picker-input) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
    }
}

::-webkit-input-placeholder {
    color: rgba(black, 0.8);
}

textarea.ant-input {
    //box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    resize: none;
    display: block;
    min-height: 197px;
    padding: 20px;

    /*&:hover,
    &:focus {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }*/
}

/* Ant checkbox */
.ant-checkbox-wrapper {
    span:not(.ant-checkbox) {
        @include font(normal, 16, 27px, $color-grey, 0);
        display: flex;
        position: relative;
        cursor: pointer;
        padding: 0;

        &:before {
            content: url(../../../assets/img/unchecked.svg);
            @extend %primary-transition;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            top: 2px;
            min-width: 22px;
            margin-right: 8px;
            fill: black;
        }
    }

    &-disabled {
        span:not(.ant-checkbox) {
            pointer-events: none;
            user-select: none;
            color: #b8b8b8;
            cursor: auto;

            &:before {
                opacity: 0.35;
            }
        }
    }

}

.ant-checkbox {
    position: absolute;
    opacity: 0;

    &.ant-checkbox-checked {
        + span {
            color: black;

            &:before {
                content: url(../../../assets/img/checked.svg);
                fill: black;
            }
        }
    }
}

/* Ant select */
.ant-select {
    width: 100% !important;

    &-open {
        .ant-select-arrow {
            transform: rotate(180deg);
        }
    }
}

.ant-select-selection {
    //box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    background-color: white;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    outline: 0;
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 50px;
    padding: 0 55px 0 15px;
    display: flex;
    align-items: center;
    overflow: hidden;

    &__rendered {
        width: 100%;
        margin-left: 0;
        line-height: 1;
    }
}

.ant-select-selection-selected-value {
    @include font(normal, 17, 1, black, 0);
    position: relative;
}

.ant-select-arrow {
    display: block;
    transition: all 0.25s ease;
    right: 21px;

    &:before {
        content: "\e906";
        display: inline-block;
        font-family: 'icomoon' !important;
        font-size: 14px;
        color: #8B8B8B;
    }

    svg {
        display: none;
    }
}

.ant-select-dropdown {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 5px;
}

.ant-select-dropdown-menu {
    margin: 0;
    padding: 0;
}

.ant-select-dropdown-menu-item {
    @include font(normal, 17, 1, black, 0);
    cursor: pointer;
    list-style: none;
    padding: 10px 15px;

    &-active:not(.ant-select-dropdown-menu-item-disabled),
    &:hover:not(.ant-select-dropdown-menu-item-disabled) {
        background-color: rgba($color-main, 0.1);
    }

    &-selected {
        background-color: rgba($color-main, 0.05);
    }

    &-disabled {
        cursor: inherit;
        opacity: 0.35;
    }
}


/* Ant datepicker */
.ant-calendar-picker {
    width: 100%;
}

.ant-calendar-picker-icon {
    display: none;
}

.ant-calendar-picker:not(.ant-date-rangepicker) {
    .ant-calendar-picker-input {
        background-image: url("../../../assets/img/date.svg");
        background-size: 18px;
        background-repeat: no-repeat;
        background-position: top 15px right 15px;
        cursor: pointer;
        //box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        padding-right: 40px !important;
    }
}

.ant-calendar-range-picker-input {
    background-image: url("../../../assets/img/date.svg");
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: top 15px right 15px;
    cursor: pointer;
    padding-right: 40px !important;
    padding-left: 9px;
    border: 1px solid #DDDDDD;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.ant-date-rangepicker {
    .ant-input,
    .ant-calendar-input {
        border: 0;
        padding: 0;
    }
}

.date-sm {
    max-width: 138px;

    &:not(.ant-date-rangepicker) .ant-calendar-picker-input {
        background-size: 16px;
        background-position: top 15px right 10px;
        padding-left: 9px;
        padding-right: 28px !important;
    }
}

.ant-calendar-picker-input {
    &:hover, &:focus {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
}

.ant-calendar {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    background-color: white;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
}

.ant-calendar-range-picker-separator {
    font-size: 0;

    &:after {
        content: '-';
        display: inline-block;
        vertical-align: middle;
        font-size: 30px;
        color: black;
    }
}

.dates_separator {
    vertical-align: middle;
    font-size: 30px;
    color: black;
    padding-top: 26px;
}

.ant-calendar-input-wrap {
    height: initial;
}

.hide-label {
    label {
        opacity: 0;
    }
}

.ant-calendar-picker-clear {
    position: absolute;
    top: 50%;
    transform: translateY(-40%);
    right: 50px;
}

.ant-calendar-date {
    font-size: 17px;
    width: 27px;
    height: 27px;

    &:focus {
        outline: 0;
    }

    &:hover {
        background-color: rgba($color-main, 0.2);
    }

    &:active {
        background-color: rgba($color-main, 0.9);
    }
}


.ant-calendar-today .ant-calendar-date {
    color: $color-main;
    border-color: $color-main;
}

.ant-calendar-selected-day {
    .ant-calendar-date {
        background-color: rgba($color-main, 0.9);
        color: white;
    }
}

.ant-calendar-my-select {
    font-size: 17px;
}

.ant-calendar-header {
    a:hover {
        color: darken($color-main, 10%);
    }
}

a {
    color: $color-main;

    &:hover {
        color: inherit;
    }
}

.ant-select-disabled .ant-select-selection {
    opacity: 0.5;
}

.has-error .ant-input-affix-wrapper .ant-input:focus,
.has-error .ant-input-affix-wrapper .ant-input,
.has-error .ant-input-affix-wrapper .ant-input:hover {
    border-color: $color-grey--lighten;
}

.has-error .ant-input-affix-wrapper,
.has-error .ant-input-affix-wrapper:hover {
    box-shadow: 0 0 5px rgba(255, 0, 0, 1);
}

.ant-btn:focus,
.ant-btn:hover {
    color: inherit;
    background-color: inherit;
    border-color: inherit;
}

.btn.ant-btn.ant-btn-loading {
    justify-content: flex-start;
}

.ant-btn:active>span.btn__icon,
.ant-btn:focus>span.btn__icon {
    position: absolute;
}

.ant-input[disabled],
.ant-input:-webkit-autofill:disabled,
.ant-input:disabled, .ant-calendar-input:disabled,
.ant-select-disabled .ant-select-selection,
.ant-select-disabled .ant-select-selection-selected-value {
    background-color: initial !important;
    cursor: not-allowed;
    color: black !important;

    + .ant-input-suffix {
        opacity: 0.5;
    }
}

.ant-select-disabled .ant-select-selection,
.ant-select-disabled .ant-select-selection-selected-value {
    //box-shadow: 0 0 0 !important;
}

.stretch-columns {
    .ant-form-item.column {
        min-width: 33.3%;
        max-width: 50%;
    }
}

.ant-table-tbody {
    & > tr > td {
        @include font(normal, 16, 24px, black, 0, $fontMardoto);
        padding: 22px 9px;
    }

    .ant-form-item:last-child {
        margin-bottom: 0;
    }

    .ant-select {
        max-width: 138px;
        width: 100%;
        font-size: 14px;

        &-arrow {
            right: 14px;
        }

        &-selection {
            padding: 0 32px 0 10px;
        }
    }

    .ant-select-selection-selected-value {
        font-size: 14px;
    }
}

.ant-table-thead {
    border-radius: 5px 5px 0 0;

    & > tr > th {
        @include font(500, 14, 22px, white, 0, $fontMardoto);
        background: #3A3A3A;
        padding: 19px 9px;
    }
}

.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-thead>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
    background: rgba($color-green, 0.1);
}

.ant-pagination {
    &-item {
        @include font(500, 16, 35px, 0, 0);
        background: white;
        border: 1px solid #DDDDDD;
        border-radius: 50%;
        width: 35px;
        height: 35px;

        a {
            color: $color-green;
        }

        &-active {
            background: linear-gradient(90deg, $color-green 0%, #00A740 100%);

            a {
                color: white;
            }

            &:hover,
            &:focus {
                border-color: $color-green;

                a {
                    color: white;
                }
            }
        }

        &:not(&-active):hover,
        &:not(&-active):focus {
            border-color: $color-green;

            a {
                color: $color-green;
            }
        }
    }

    &-prev,
    &-next {
        width: 35px;
        height: 35px;

        .ant-pagination-item-link {
            border-radius: 50%;
            line-height: 35px;
        }

        &:hover {
            .ant-pagination-item-link {
                border-color: $color-green;
                color: $color-green;
            }
        }
    }
}

.ant-tabs {
    overflow: visible;
}

.ant-tabs-bar {
    border-color: #DDDDDD;
    margin-bottom: 40px;
}

.ant-tabs-nav .ant-tabs-tab {
    @include font(normal, 20, 24px, #8B8B8B, 0, $fontMardoto);
    padding: 19px 0 31px;
    margin-right: 49px;

    &:hover {
        color: $color-green;
    }
}

.ant-tabs-nav .ant-tabs-tab-active {
    font-weight: 900;
    color: $color-green;

    .tab_icon {
        background: linear-gradient(90deg, $color-green 0%, #00A740 100%);
        color: white;
    }
}

.tab_icon {
    background-color: #DDDDDD;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    color: #8B8B8B;
    margin-right: 10px;
}

.icon.tab_icon {
    display: inline-flex;
}

.ant-tabs-ink-bar {
    background-color: $color-green;
    height: 3px;
}

.ant-table-pagination.ant-pagination {
    float: none;
    margin-top: 60px;
}

.ant-table-thead>tr>th.ant-table-column-sort {
    background: #3A3A3A;
}

.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters:hover,
.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon,
.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter {
    background: #3A3A3A;

    & > tr > th {
        border-bottom: 0;
        padding: 19px 9px;
    }
}

.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on,
.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on {
    color: $color-green;
}

.base_modal {
    min-width: 650px;

    .ant-modal-header {
        padding: 23px 24px;
    }

    .ant-modal-body {
        padding: 25px 25px 0;
    }

    .ant-modal-footer {
        padding: 18px 16px;
    }
}

.user_info_modal {
    min-width: 1050px;

    .ant-modal-header {
        padding: 18px 18px;
    }

    .ant-modal-body {
        padding: 18px 18px 0;
    }

    .ant-modal-footer {
        padding: 18px 16px;
    }
}

.ant-spin {
    color: $color-green;
}

.ant-spin-dot-item {
    background-color: $color-green;
}

.ant-select-selection--multiple {
  height: auto;
  min-height: 50px;
  padding: 5px 10px;
}

.ant-input-number,
.ant-input-number-focused {
  width: 100%;
  box-shadow: none;
  background-color: white;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  outline: 0;
  cursor: text;
  position: relative;
  height: 50px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  overflow: hidden;
  .ant-input-number-input-wrap {
    @include font(normal, 18, 48px, black, 0);
    min-width: 100%;
  }
  .ant-input-number-input {
    padding: 0;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
}

.ant-input-number-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.ant-message-custom-content {
  display: flex;
}

.phone-code-selectbox .ant-select-selection--single {
  padding: 0 40px 0 10px;
}

.ant-calendar-input-invalid {
  border: 1px solid red !important;
}