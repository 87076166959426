@import "src/assets/scss/utils/_variables.scss";

.video-tutorials-screen-wrapper {
  font: normal 18px/40px "Mardoto", Arial, sans-serif;
  .main-section-wrapper {
    margin-bottom: 40px;
    .title-wrapper {
      color: $color-main;
    }
  }
  .video-item-wrapper {
    width: 450px;
    .video-title {
      text-decoration: underline;
    }
    iframe {
      width: 100%;
      height: 300px;
    }
    hr {
      border: 0;
      height: 1px;
      background: #333;
      background-image: linear-gradient(to right, #ccc, #333, #ccc);
    }
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}
