@import "../../assets/scss/utils/module";

.tabs {
    border-bottom: 1px solid $color-grey--lighten;
    list-style: none;
    padding: 0;
    margin: 0;

    > * {
        &:not(:last-child) {
            .tab:after {
                content: "\e904";
                @extend %inline-middle;
                font-family: 'icomoon' !important;
                font-size: 12px;
                color: $color-grey--lighten;
                margin-left: 30px;
            }
        }
    }
}

.tab {
    display: inline-block;
    position: relative;
    padding: 34px 0 30px;
    margin-right: 30px;

    &__number {
        @include font(normal, 18, 1, $color-grey, 0, $fontMontserratArm);
        @extend %inline-middle;
        background-color: $color-grey--lighten;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin-right: 10px;
    }

    &__text {
        @include font(normal, 20, 1, $color-grey, 0);
        @extend %inline-middle;
    }

    &.active {
        .tab {
            &__number {
                background: $gradient-green;
                font-weight: bold;
                color: white;
            }

            &__text {
                font-weight: 900;
                color: $color-green;
            }
        }

        &:before {
            content: '';
            background-color: $color-green;
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
            width: 100%;
            height: 3px;
        }
    }
}

@media (max-width: 1325px) {
    .tab {
        &__text {
            width: calc(100% - 82px);
        }
    }
}

@media (max-width: 1024px) {
    .tab {
        &__number {
            font-size: 16px;
            width: 25px;
            height: 25px;
            line-height: 25px;
        }

        &__text {
            width: calc(100% - 35px);
            font-size: 18px;
            line-height: 23px;
        }

        &:not(:last-child) {
            margin-right: 30px;

            &:after {
                position: absolute;
                top: 50%;
                right: -5px;
                font-size: 12px;
                margin-left: 30px;
            }
        }
    }
}