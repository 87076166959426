@import "../../assets/scss/utils/module";

.income_card {
    //background:  linear-gradient(to right, #333 30%, rgba(255, 255, 255, 0) 0%),
    //linear-gradient(to bottom, #333 30%, rgba(255, 255, 255, 0) 0%),
    //linear-gradient(to bottom, #333 30%, rgba(255, 255, 255, 0) 0%),
    //linear-gradient(to right, #333 30%, rgba(255, 255, 255, 0) 0%);
    //background-position: top 0 left 0, top 0 right 0, top 0 right 0, bottom 0 left 0;
    //background-size: 10px 10px, 20px 10px, 20px 10px, 10px 10px;
    //background-repeat: repeat-x, repeat-y, repeat-y, repeat-x;

    background-color: #F2F2F2;
    border: 2px dashed #BDBDBD;
    border-radius: 8px;
    padding: 18px 30px;
    margin-bottom: 20px;

    &__text {
        @include font(500, 16, 29px, black, 0);
    }

    &__actions {
        @extend %flex;
        @extend %align-middle;
        font-size: 16px;

        .cust_btn {
            margin-left: 30px;
        }
    }
}