@import "../../assets/scss/utils/module";

.ant-popover-content {
    min-width: 450px;
    max-width: 450px;
}

.ant-popover-inner {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    overflow: hidden;
}

.ant-popover-placement-bottom>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-bottomLeft>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-bottomRight>.ant-popover-content>.ant-popover-arrow {
    border-color: #303030;
    border-width: 6.242641px;
}

.ant-popover-inner-content {
    padding: 0;
}

.popover_header {
    background-color: #303030;
    padding: 14px 0;
}

.popover_main {
    max-height: 78vh;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #bfbfbf;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.popover_footer {
    padding: 16px 0;
}