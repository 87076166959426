@import "../../assets/scss/utils/module";

.notice {
    border-bottom: 1px solid #EDEDED;
    padding: 20px 15px;

    &_icon {
        background-color: #EDEDED;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
    }

    &_caption {
        flex: 1 0 330px;
        margin-bottom: 7px;
    }

    &_message {
        color: black;
    }

    &_desc {
        @include font(normal, 16, 19px, black, 0, $fontMardoto);
        margin-top: 4px;

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &_btn_group {
        margin-left: 49px;

        .base_btn {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    &-unread {
        background-color: #FBFBFB;
    }
}

.notifications_all {
    .notice_caption {
        margin-bottom: 0;
    }
}