.news-block-wrapper {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 25px;
  padding: 20px;
  .date-wrapper {
    margin-bottom: 10px;
  }
  &:not(last-child) {
    margin-bottom: 10px;
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 10px 20px;
  }
}