@import "../../assets/scss/utils/module";

$colors: (
    btn-grey #EDEDED $gradient-red $gradient-red--reverse,
    btn-green $gradient-green white $gradient-green--reverse,
);

$borders: (
    btn-grey 0 solid transparent #D8D8D8 transparent,
    btn-green 0 solid transparent rgba(255, 255, 255, 0.35) transparent,
    btn-dashed 2.5px dashed #BABABA transparent $gradient-green
);

$radius: 100px;
$height: 60px;
$width: 300px;

$largeWidth: 462px;

.btn_proto {
    border-radius: $radius;
    height: $height;
}

.btn_link {
    @include font(900, 22, 55px, white, uppercase, $fontMontserratArm);
    @extend %primary-transition;
    text-decoration: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border: 1px solid #565656;
    display: table;
    padding: 0 25px;
    margin: 0 auto;

    span {
        color: $color-green;
        font-size: 30px;
        margin-right: 3px;
    }

    &:hover {
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.15);
        color: white;
    }
}

.btn {
    @include font(800, 18, 1, black, uppercase, $fontMontserratArm);
    @extend %primary-transition;
    @extend %user-cursor;
    background-color: white;
    border: 3px solid $color-grey--lighten;
    border-radius: $radius;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    outline: none;
    min-width: 300px;
    height: 60px;
    line-height: 57px;
    padding: 0 0 0 23px;

    &:focus, &:hover {
        border-color: $color-grey--lighten;
    }

    & span:not(&__icon) {
        background: $gradient-green;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &__icon {
        display: inline-block;
        text-align: center;
        border: 3px solid $color-grey--lighten;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        line-height: 60px;
        position: absolute;
        right: -2px;
        top: -3px;

        .icon {
            display: inline-block;
            background: $gradient-green;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &:hover {
        //&:after {
        //    visibility: visible;
        //}
    }

    //&:after {
    //    content: '';
    //    @extend %primary-transition;
    //    background: inherit;
    //    border-radius: inherit;
    //    visibility: hidden;
    //    display: block;
    //    position: absolute;
    //    left: 0;
    //    top: 0;
    //    width: 100%;
    //    height: 100%;
    //}

    &:not(:active):after {
        animation: md-btn-ripple 1.5s cubic-bezier(0.12, 0.13, 0.51, 1.03);
    }

    @each $type in $colors {
        &.#{nth($type, 1)} {
            background: #{nth($type, 2)};

            span:not(.btn__icon) {
                background: #{nth($type, 3)};
                -webkit-background-clip: text;
                background-clip: text;
            }

            .btn__icon {
                top: 0;
                right: 0;

                .icon {
                    background: #{nth($type, 3)};
                    -webkit-background-clip: text;
                    background-clip: text;
                }
            }

            //&:after {
            //    background: #{nth($type, 2)};
            //}

            //&:hover {
            //
            //    &:after {
            //        visibility: visible;
            //    }
            //}
        }
    }

    @each $type in $borders {
        &.#{nth($type, 1)} {
            border: nth($type, 2) nth($type, 3) nth($type, 4);

            .btn__icon {
                background: nth($type, 6);
                border-color: nth($type, 5);
            }
        }
    }

    &.btn-dashed {
        .btn__icon {
            width: 40px;
            height: 40px;
            line-height: 40px;
            margin: 10px;

            .icon {
                background: white;
                -webkit-background-clip: text;
                background-clip: text;
            }
        }
    }

    &.btn-fluid {
        width: 100%;
    }

    &.btn-wide {
        max-width: $largeWidth;
        width: 100%;
    }

    &.disabled,
    &:disabled {
        pointer-events: none;
        opacity: 0.35;
    }
}

.btn_group {
    &:not(:last-child) {
        margin-right: 24px;
    }
}

.loading {
    overflow: hidden;
    pointer-events: none;
    opacity: 0.35;

    &:before {
        //content: "";
        //position: absolute;
        //top: 5px;
        //right: 5px;
        //width: 45px;
        //height: 45px;
        //border-radius: 50%;
        //border: 4px solid #fff;
        //border-color: #fff transparent #fff transparent;
        //animation: lds-dual-ring 1.5s linear infinite;
        //z-index: 2;
    }
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.base_btn {
    @include font(900, 13, 31px, 0, uppercase, $fontMardoto);
    @extend %primary-transition;
    border-radius: 5px;
    border: 0;
    outline: 0;
    cursor: pointer;
    display: inline-block;
    height: 31px;
    padding: 0 8px;

    &:not(&-green):not(&-red) {
        background-color: #ECF1EE;
        color: $color-green;

        &:hover {
            background-color: darken(#ECF1EE, 10%);
        }
    }

    .icon {
        display: inline-block;
        vertical-align: -3px;
        margin-right: 8px;
        color: inherit;
    }

    &-green {
        background-color: $color-green;
        color: white;

        &:hover {
            background-color: darken($color-green, 10%);
        }
    }

    &-red {
        background-color: $color-red;
        color: white;

        &:hover {
            background-color: darken($color-red, 10%);
        }
    }
}