@import "../utils/_variables";

.row {
    max-width: $rowWidth;
    margin-right: auto;
    margin-left: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.row .row {
    margin-right: -$columnSpace;
    margin-left: -$columnSpace;
}

@media print, screen and (min-width: $mediaMedium) {
    .row .row {
        margin-right: -$columnSpace;
        margin-left: -$columnSpace;
    }
}

@media print, screen and (min-width: $mediaLarge) {
    .row .row {
        margin-right: -$columnSpace;
        margin-left: -$columnSpace;
    }
}

.row .row.collapse {
    margin-right: 0;
    margin-left: 0;
}

.row.expanded {
    max-width: none;
}

.row.expanded .row {
    margin-right: auto;
    margin-left: auto;
}

.row:not(.expanded) .row {
    max-width: none;
}

.row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0;
}

.row.is-collapse-child, .row.collapse > .column > .row, .row.collapse > .columns > .row {
    margin-right: 0;
    margin-left: 0;
}

.column, .columns {
    -ms-flex: 1 1 0px;
    flex: 1 1 0px;
    padding-right: $columnSpace;
    padding-left: $columnSpace;
}

.column.row.row, .row.row.columns {
    display: block;
}

.row .column.row.row, .row .row.row.columns {
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
}

.small-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.small-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.small-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.small-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.small-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.small-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.small-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.small-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.small-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}

.small-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.small-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.small-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.small-up-1 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.small-up-1 > .column, .small-up-1 > .columns {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.small-up-2 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.small-up-2 > .column, .small-up-2 > .columns {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.small-up-3 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.small-up-3 > .column, .small-up-3 > .columns {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.small-up-4 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.small-up-4 > .column, .small-up-4 > .columns {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.small-up-5 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.small-up-5 > .column, .small-up-5 > .columns {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}

.small-up-6 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.small-up-6 > .column, .small-up-6 > .columns {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.small-up-7 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.small-up-7 > .column, .small-up-7 > .columns {
    -ms-flex: 0 0 14.28571%;
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
}

.small-up-8 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.small-up-8 > .column, .small-up-8 > .columns {
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
}

.small-collapse > .column, .small-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns {
    padding-right: $columnSpace;
    padding-left: $columnSpace;
}

@media print, screen and (min-width: $mediaMedium) {
    .medium-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .medium-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .medium-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .medium-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .medium-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .medium-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .medium-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .medium-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .medium-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .medium-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .medium-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .medium-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .medium-up-1 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .medium-up-1 > .column, .medium-up-1 > .columns {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .medium-up-2 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .medium-up-2 > .column, .medium-up-2 > .columns {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .medium-up-3 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .medium-up-3 > .column, .medium-up-3 > .columns {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .medium-up-4 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .medium-up-4 > .column, .medium-up-4 > .columns {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .medium-up-5 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .medium-up-5 > .column, .medium-up-5 > .columns {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .medium-up-6 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .medium-up-6 > .column, .medium-up-6 > .columns {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .medium-up-7 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .medium-up-7 > .column, .medium-up-7 > .columns {
        -ms-flex: 0 0 14.28571%;
        flex: 0 0 14.28571%;
        max-width: 14.28571%;
    }

    .medium-up-8 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .medium-up-8 > .column, .medium-up-8 > .columns {
        -ms-flex: 0 0 12.5%;
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }
}

@media print, screen and (min-width: $mediaMedium) and (min-width: $mediaMedium) {
    .medium-expand {
        -ms-flex: 1 1 0px;
        flex: 1 1 0px;
    }
}

.row.medium-unstack > .column, .row.medium-unstack > .columns {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
}

@media print, screen and (min-width: $mediaMedium) {
    .row.medium-unstack > .column, .row.medium-unstack > .columns {
        -ms-flex: 1 1 0px;
        flex: 1 1 0px;
    }
}

@media print, screen and (min-width: $mediaMedium) {
    .medium-collapse > .column, .medium-collapse > .columns {
        padding-right: 0;
        padding-left: 0;
    }

    .medium-uncollapse > .column, .medium-uncollapse > .columns {
        padding-right: $columnSpace;
        padding-left: $columnSpace;
    }
}

@media print, screen and (min-width: $mediaMediumLarge) {
    .medium-large-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .medium-large-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .medium-large-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .medium-large-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .medium-large-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .medium-large-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .medium-large-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .medium-large-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .medium-large-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .medium-large-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .medium-large-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .medium-large-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .medium-large-up-1 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .medium-large-up-1 > .column,
    .medium-large-up-1 > .columns {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .medium-large-up-2 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .medium-large-up-2 > .column,
    .medium-large-up-2 > .columns {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .medium-large-up-3 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .medium-large-up-3 > .column,
    .medium-large-up-3 > .columns {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .medium-large-up-4 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .medium-large-up-4 > .column,
    .medium-large-up-4 > .columns {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .medium-large-up-5 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .medium-large-up-5 > .column,
    .medium-large-up-5 > .columns {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .medium-large-up-6 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .medium-large-up-6 > .column,
    .medium-large-up-6 > .columns {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .medium-large-up-7 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .medium-large-up-7 > .column,
    .medium-large-up-7 > .columns {
        -ms-flex: 0 0 14.28571%;
        flex: 0 0 14.28571%;
        max-width: 14.28571%;
    }

    .medium-large-up-8 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .medium-large-up-8 > .column,
    .medium-large-up-8 > .columns {
        -ms-flex: 0 0 12.5%;
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }

    .medium-large-flex-container {
        display: -ms-flexbox;
        display: flex;
    }
}

@media print, screen and (min-width: $mediaLarge) {
    .large-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .large-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .large-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .large-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .large-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .large-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .large-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .large-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .large-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .large-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .large-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .large-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .large-up-1 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .large-up-1 > .column, .large-up-1 > .columns {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .large-up-2 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .large-up-2 > .column, .large-up-2 > .columns {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .large-up-3 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .large-up-3 > .column, .large-up-3 > .columns {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .large-up-4 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .large-up-4 > .column, .large-up-4 > .columns {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .large-up-5 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .large-up-5 > .column, .large-up-5 > .columns {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .large-up-6 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .large-up-6 > .column, .large-up-6 > .columns {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .large-up-7 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .large-up-7 > .column, .large-up-7 > .columns {
        -ms-flex: 0 0 14.28571%;
        flex: 0 0 14.28571%;
        max-width: 14.28571%;
    }

    .large-up-8 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .large-up-8 > .column, .large-up-8 > .columns {
        -ms-flex: 0 0 12.5%;
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }
}

@media print, screen and (min-width: $mediaLarge) and (min-width: $mediaLarge) {
    .large-expand {
        -ms-flex: 1 1 0px;
        flex: 1 1 0px;
    }
}

.row.large-unstack > .column, .row.large-unstack > .columns {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
}

@media print, screen and (min-width: $mediaLarge) {
    .row.large-unstack > .column, .row.large-unstack > .columns {
        -ms-flex: 1 1 0px;
        flex: 1 1 0px;
    }
}

@media print, screen and (min-width: $mediaLarge) {
    .large-collapse > .column, .large-collapse > .columns {
        padding-right: 0;
        padding-left: 0;
    }

    .large-uncollapse > .column, .large-uncollapse > .columns {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem;
    }
}

.shrink {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
}

.column-block {
    margin-bottom: 1.875rem;
}

.column-block > :last-child {
    margin-bottom: 0;
}

.align-right {
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.align-center {
    -ms-flex-pack: center;
    justify-content: center;
}

.align-justify {
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.align-spaced {
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.align-right.vertical.menu > li > a {
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.align-center.vertical.menu > li > a {
    -ms-flex-pack: center;
    justify-content: center;
}

.align-top {
    -ms-flex-align: start;
    align-items: flex-start;
}

.align-self-top {
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.align-bottom {
    -ms-flex-align: end;
    align-items: flex-end;
}

.align-self-bottom {
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.align-middle {
    -ms-flex-align: center;
    align-items: center;
}

.align-self-middle {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.align-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
}

.align-self-stretch {
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
}

.align-center-middle {
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
}

.small-order-1 {
    -ms-flex-order: 1;
    order: 1;
}

.small-order-2 {
    -ms-flex-order: 2;
    order: 2;
}

.small-order-3 {
    -ms-flex-order: 3;
    order: 3;
}

.small-order-4 {
    -ms-flex-order: 4;
    order: 4;
}

.small-order-5 {
    -ms-flex-order: 5;
    order: 5;
}

.small-order-6 {
    -ms-flex-order: 6;
    order: 6;
}

@media print, screen and (min-width: $mediaMedium) {
    .medium-order-1 {
        -ms-flex-order: 1;
        order: 1;
    }

    .medium-order-2 {
        -ms-flex-order: 2;
        order: 2;
    }

    .medium-order-3 {
        -ms-flex-order: 3;
        order: 3;
    }

    .medium-order-4 {
        -ms-flex-order: 4;
        order: 4;
    }

    .medium-order-5 {
        -ms-flex-order: 5;
        order: 5;
    }

    .medium-order-6 {
        -ms-flex-order: 6;
        order: 6;
    }
}

@media print, screen and (min-width: $mediaMediumLarge) {
    .medium-large-order-1 {
        -ms-flex-order: 1;
        order: 1;
    }

    .medium-large-order-2 {
        -ms-flex-order: 2;
        order: 2;
    }

    .medium-large-order-3 {
        -ms-flex-order: 3;
        order: 3;
    }

    .medium-large-order-4 {
        -ms-flex-order: 4;
        order: 4;
    }

    .medium-large-order-5 {
        -ms-flex-order: 5;
        order: 5;
    }

    .medium-large-order-6 {
        -ms-flex-order: 6;
        order: 6;
    }
}

@media print, screen and (min-width: $mediaLarge) {
    .large-order-1 {
        -ms-flex-order: 1;
        order: 1;
    }

    .large-order-2 {
        -ms-flex-order: 2;
        order: 2;
    }

    .large-order-3 {
        -ms-flex-order: 3;
        order: 3;
    }

    .large-order-4 {
        -ms-flex-order: 4;
        order: 4;
    }

    .large-order-5 {
        -ms-flex-order: 5;
        order: 5;
    }

    .large-order-6 {
        -ms-flex-order: 6;
        order: 6;
    }
}

.flex-container {
    display: -ms-flexbox;
    display: flex;
}

.flex-child-auto {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.flex-child-grow {
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
}

.flex-child-shrink {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
}

.flex-dir-row {
    -ms-flex-direction: row;
    flex-direction: row;
}

.flex-dir-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.flex-dir-column {
    -ms-flex-direction: column;
    flex-direction: column;
}

.flex-dir-column-reverse {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
}

@media print, screen and (min-width: $mediaMedium) {
    .medium-flex-container {
        display: -ms-flexbox;
        display: flex;
    }

    .medium-flex-child-auto {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    }

    .medium-flex-child-grow {
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
    }

    .medium-flex-child-shrink {
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
    }

    .medium-flex-dir-row {
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .medium-flex-dir-row-reverse {
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }

    .medium-flex-dir-column {
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .medium-flex-dir-column-reverse {
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
}

@media print, screen and (min-width: $mediaLarge) {
    .large-flex-container {
        display: -ms-flexbox;
        display: flex;
    }

    .large-flex-child-auto {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    }

    .large-flex-child-grow {
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
    }

    .large-flex-child-shrink {
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
    }

    .large-flex-dir-row {
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .large-flex-dir-row-reverse {
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }

    .large-flex-dir-column {
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .large-flex-dir-column-reverse {
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
}