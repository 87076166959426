@import "../../assets/scss/utils/module";

.h-title {
    font-family: $fontMontserratArm;
    text-transform: uppercase;
    line-height: 1.4;
    color: black;

    &.xl {
        font-size: 32px;
    }

    &.lg {
        font-size: 25px;
    }

    &.md {
        font-size: 23px;
    }

    &.xs {
        font-size: 15px;
    }

    &.black {
        font-weight: 900;
    }

    &.extrabold {
        font-weight: 800;
    }

    &.bold {
        font-weight: bold;
    }

    &.medium {
        font-weight: 500;
    }

    &__icon {
        border-radius: 50%;
        border: 1px solid $color-grey--lighten;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-right: 10px;
        width: 45px;
        height: 45px;
        line-height: 47px;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        .icon {
            background: $gradient-green;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}

.capitalize-first-letter {
  text-transform: none !important;
  &::first-letter{
    text-transform: uppercase;
  }
}

.lowercase-letter {
    text-transform: lowercase !important;
}
