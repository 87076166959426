@import "../../assets/scss/utils/module";

.person_ava {
    &__img {
        @extend %inline-middle;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center; // center
        border-radius: 50%;
        width: 72px;
        height: 72px;
        margin-right: 15px;
        transition: transform .2s;
        cursor: zoom-in;
        z-index: 1;
    }
}

.person-avatar-image-wrapper {
  width: 60px;
  height: 85px;
  transform: scale(2);
  cursor: zoom-out;
  user-select: none;
  transition: transform .2s;
  z-index: 1;
}

@media (max-width: 992px) {
    .person_ava {
        margin-bottom: 30px;
    }
}