@import "../../assets/scss/utils/module";

.main_content {
    padding-top: 30px;
    padding-bottom: 100px;
}

@media (max-width: 767px) {
    .main_content {

    }
}
